.custom-page-header {
  margin: 0 2rem 1.5rem;
  border-bottom: 1px solid var(--divider);
  padding: 0.5rem 0 0.75rem;
  .title {
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.custom-layout {
  height: calc(100vh - 9.7rem);
  position: relative;
  .custom-layout-content {
    height: calc(100% - 6rem);
    padding: 0 1rem;
    overflow-y: auto;
  }
  .custom-layout-action-buttons {
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    background: var(--body-background);
    display: flex;
    justify-content: center;
  }
}

.custom-tour-card.shepherd-element {
  background: var(--card-background) !important;
  .shepherd-arrow:before {
    background: var(--card-background) !important;
  }
  .shepherd-header {
    background: var(--card-background) !important;
    padding: 1rem !important;
    .shepherd-title {
      color: var(--text-data) !important;
      font-weight: bold !important;
    }
  }
  .shepherd-text {
    color: var(--text-data) !important;
    line-height: 1.5rem !important;
    padding: 0 1rem 1rem !important;
  }
}

.custom-message-panel.p-overlaypanel {
  margin-top: 15px !important;
  background: transparent !important;
  border-radius: 10px !important;
  &:before {
    border-width: 12px !important;
    margin-left: 5rem !important;
  }
  &:after {
    border-width: 12px !important;
    margin-left: 5rem !important;
  }
  &::before {
    content: ' ' !important;
    border-top-color: transparent !important;
    border-bottom-color: var(--input-background) !important;
  }
  &::after {
    content: ' ' !important;
    border-top-color: transparent !important;
    border-bottom-color: var(--input-background) !important;
  }
  .p-overlaypanel-content {
    border-radius: 10px !important;
    border: none !important;
  }
}

.custom-border {
  border: 1px solid var(--divider) !important;
}

.custom-icon-button {
  cursor: pointer !important;
  svg {
    color: #999999 !important;
  }
  &.selected {
    svg {
      color: var(--microsec-highlight) !important;
    }
  }
  &:not(.selected) {
    &:hover {
      svg {
        color: #eeeeee !important;
      }
    }
  }
}

::ng-deep .open-dropdown {
  .p-dropdown {
    border: none;
    background: transparent !important;
  }
  .p-dropdown-label {
    padding: 5px 10px;
    border: 1px solid #00bb83 !important;
    border-radius: 3px;
    text-transform: uppercase;
    margin-right: 1.5rem;
    height: 1.875rem;
    color: #00bb83 !important;
  }
  .p-dropdown-trigger-icon {
    color: #eeeeee;
  }
}

::ng-deep .closed-dropdown {
  .p-dropdown {
    border: none;
    background: transparent !important;
  }
  .p-dropdown-label {
    padding: 5px 10px;
    border: 1px solid #fb4d58 !important;
    border-radius: 3px;
    text-transform: uppercase;
    margin-right: 1.5rem;
    height: 1.875rem;
    color: #fb4d58 !important;
  }
  .p-dropdown-trigger-icon {
    color: #eeeeee;
  }
}

::ng-deep .fixing-dropdown {
  .p-dropdown {
    border: none;
    background: transparent !important;
  }
  .p-dropdown-label {
    padding: 5px 10px;
    border: 1px solid #ff9534 !important;
    border-radius: 3px;
    text-transform: uppercase;
    margin-right: 1.5rem;
    height: 1.875rem;
    color: #ff9534 !important;
  }
  .p-dropdown-trigger-icon {
    color: #eeeeee;
  }
}

::ng-deep .archived-dropdown {
  .p-dropdown {
    border: none;
    background: transparent !important;
  }
  .p-dropdown-label {
    padding: 5px 10px;
    border: 1px solid #888888 !important;
    border-radius: 3px;
    text-transform: uppercase;
    margin-right: 1.5rem;
    height: 1.875rem;
    color: #888888 !important;
  }
  .p-dropdown-trigger-icon {
    color: #eeeeee;
  }
}
